<template>
  <div id="app">
    <notifications position="top right" classes="custom-notifications" />
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  max-height: 100000vh;
  padding-bottom: 30px;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: $red;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.custom-notifications {
  padding: 10px;
  font-size: 14px;
  color: $white;
  background: #44A4FC;
  border-right: 5px solid #187FE7;

  &.warn {
    background: #ffb648;
    border-right: #f48a06;
  }

  &.error {
    background: #E54D42;
    border-right: #B82E24;
  }

  &.success {
    background: #68CD86;
    border-right-color: #42A85F;
  }
}
</style>
