import axios from 'axios';
import JwtService from './jwt.service';

const ApiService = {
  setHeader() {
    axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`;
  },
  query(resource, params) {
    return axios.get(resource, params).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  get(resource, slug = '') {
    return axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  post(resource, params) {
    return axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return axios.patch(`${resource}/${slug}`, params);
  },

  delete(resource) {
    return axios.delete(resource).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },
};

export default ApiService;
