import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import JwtService from '@/common/services/jwt.service'; // import the instance

const AxiosService = {
  init() {
    if (process.env.NODE_ENV === 'development') {
      axios.defaults.baseURL = `http://${window.location.hostname}:3000/api/v1`;
    } else {
      axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    }

    axios.defaults.headers.common.Accept = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    axios.interceptors.response.use(
      response => response,
      (error) => {
        // expired token or unauthenticated
        if (error.response.status === 401 && router.currentRoute.name !== 'Login') { // Unauthorized
          JwtService.destroyToken();
          router.replace({ name: 'Login' });
          Vue.notify({
            title: 'Unauthenticated',
            text: 'You are not authenticated to complete this action.',
            type: 'error',
            width: 350,
          });
        } else if (error.response.status === 403) { // Unauthenticated
          router.go(-1);
          Vue.notify({
            title: 'Unauthorized',
            text: 'You are not authorized to complete this action.',
            type: 'error',
            width: 350,
          });
        } else if (error.response.status === 404) { // Not Found
          // router.go(-1);
          Vue.notify({
            title: 'Not Found',
            text: 'The record cannot be found.',
            type: 'error',
            width: 350,
          });
        }

        return Promise.reject(error);
      },
    );
  },
};

export default AxiosService; // export axios instance to be imported in your app
