// Plugins
import Vue from 'vue';
import Notifications from 'vue-notification';
import vSelect from 'vue-select';
import JsonCSV from 'vue-json-csv';

// Font Awesome Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faTimes,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';

// Components
import BootstrapVue from 'bootstrap-vue';

// Main Configurations
import App from './App.vue';
import router from './router';
import AxiosService from './common/services/axios.service';
import HoneybadgerVue from './common/services/honeybadger.service';

// Bootstrap Styles
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Extra Plugins
Vue.use(Notifications);
Vue.use(BootstrapVue);
Vue.component('v-select', vSelect);
Vue.component('downloadCsv', JsonCSV);

// Font Awesome additions
library.add(faTimes);
library.add(faQuestionCircle)

// Font Awesome Components
Vue.component('font-awesome-icon', FontAwesomeIcon);

AxiosService.init();
Vue.config.productionTip = false;

// Honeybadger
if (process.env.NODE_ENV !== 'development') {
  Vue.use(HoneybadgerVue, {
    apiKey: 'b89adb0e',
    environment: process.env.NODE_ENV,
    debug: process.env.NODE_ENV === 'development',
  });
}

Vue.prototype.$eventHub = new Vue(); // Global event bus, toot toot

export default new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
