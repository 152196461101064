import axios from 'axios';

const ID_TOKEN_KEY = 'id_token';

const JwtService = {
  getToken() {
    return localStorage.getItem(ID_TOKEN_KEY);
  },

  saveToken(token) {
    localStorage.setItem(ID_TOKEN_KEY, token);
  },

  destroyToken() {
    localStorage.removeItem(ID_TOKEN_KEY);
    delete axios.defaults.headers.common.Authorization;
  },

  parseJwt() {
    const token = this.getToken();
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    }
    return null;
  },

  isTokenExpired() {
    const token = this.parseJwt();
    const now = Math.floor(Date.now() / 1000);
    return token !== null && token.exp < now;
  },
};

export default JwtService;
