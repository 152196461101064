import Vue from 'vue';
import ApiService from './api.service';
import JwtService from './jwt.service';
import router from '../../router';

const AuthService = {
  login(email, password, redirect = '/') {
    return ApiService.post('users/sign_in', { user: { email, password } })
      .then((response) => {
        if (response.data) {
          // If the user is not a 'Trainee' role, keep them from logging in
          if (response.data.user.role !== 'Trainee') {
            router.go();
            JwtService.saveToken(response.data.user.token);
            ApiService.setHeader();
            router.push({ path: redirect }, () => {
              Vue.notify({
                title: 'Success',
                text: 'Successful log in!',
                type: 'success',
                width: 350,
              });
            });
          } else {
            this.logout();
            Vue.notify({
              title: 'Unable to Login',
              text: 'Your account does not have login privileges.',
              type: 'error',
              width: 350,
            });
          }
        }
      });
  },

  recover(email) {
    return ApiService.post('users/recover', { user: { email } })
      .then((response) => {
        if (response.data) {
          Vue.notify({
            title: 'Success',
            text: 'Sent account recovery instructions to email',
            type: 'success',
            width: 350,
          });
        }
      });
  },

  reset_password(resetPasswordToken, password, passwordConfirmation) {
    return ApiService.post('users/reset_password', { user: { resetPasswordToken, password, passwordConfirmation } })
      .then((response) => {
        if (response.data) {
          Vue.notify({
            title: 'Success',
            text: 'Reset Password',
            type: 'success',
            width: 350,
          });
        }
      });
  },

  logout() {
    JwtService.destroyToken();
    router.push({ name: 'Login' });
  },

  checkAuth(next, from, to = '') {
    if (JwtService.getToken() && !JwtService.isTokenExpired()) {
      ApiService.setHeader();

      if (to.name === 'Login') {
        next({ path: '/' });
      } else {
        const jwt = JwtService.parseJwt();

        if (jwt.role === 'Sales Coach' || jwt.role === 'Service Coach') {
          const permittedRoutes = [
            'TimeCardIndex',
            'TimeCardShow',
            'TimeCardEdit',
            'UserEdit',
            'UserShow',
          ];

          if (permittedRoutes.includes(to.name)) {
            next();
          } else {
            next({ name: 'TimeCardIndex' });
          }
        } else {
          next();
        }
      }
    } else {
      JwtService.destroyToken();

      const permittedRoutes = [
        'Login',
        'AccountRecovery',
        'PasswordReset',
      ];

      if (permittedRoutes.includes(to.name)) {
        next();
      } else {
        next({ name: 'Login' });
      }
    }
  },
};

export default AuthService;
