import Vue from 'vue';
import Router from 'vue-router';
import AuthService from './common/services/auth.service';

Vue.use(Router);
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home'),
      children: [
        // ---------------------- Time Cards ----------------------
        {
          path: '/',
          name: 'TimeCardIndex',
          component: () => import('@/views/time_cards/index.vue'),
        },
        {
          path: '/activity_reports/:id',
          name: 'TimeCardShow',
          component: () => import('@/views/time_cards/show'),
        },
        {
          path: '/activity_reports/:id/edit',
          name: 'TimeCardEdit',
          component: () => import('@/views/time_cards/edit'),
        },
        {
          path: '/activity_reports/new',
          name: 'TimeCardNew',
          component: () => import('@/views/time_cards/new'),
        }, // ---------------------- Time Card Management ----------------------
        {
          path: '/time_management',
          name: 'TimeManagementIndex',
          component: () => import('@/views/time_management/index.vue'),
        },
        {
          path: '/time_management/:id',
          name: 'TimeManagementReview',
          component: () => import('@/views/time_management/review'),
        },
        {
          path: '/time_management/:id/edit',
          name: 'TimeManagementEdit',
          component: () => import('@/views/time_management/edit'),
        },
        // ---------------------- Reports ----------------------
        {
          path: '/reports',
          name: 'ReportsIndex',
          component: () => import('@/views/reports/index.vue'),
        },
        {
          path: '/reports/user',
          name: 'UserReportsIndex',
          component: () => import('@/views/reports/user.vue'),
        },
        {
          path: '/reports/trained',
          name: 'ReportsTrained',
          component: () => import('@/views/reports/trained.vue'),
        },
        {
          path: '/reports/market_area',
          name: 'MarketAreaReport',
          component: () => import('@/views/reports/market_area.vue'),
        },
        // {
        //   path: '/time_management/:id',
        //   name: 'TimeManagementReview',
        //   component: () => import('@/views/time_management/review'),
        // },
        // ---------------------- Topics ----------------------
        {
          path: '/topics',
          name: 'TopicIndex',
          component: () => import('@/views/topics/index'),
        },
        {
          path: '/topics/new',
          name: 'TopicNew',
          component: () => import('@/views/topics/new'),
        },
        {
          path: '/topics/:id',
          name: 'TopicShow',
          component: () => import('@/views/topics/show'),
        },
        {
          path: '/topics/:id/edit',
          name: 'TopicEdit',
          component: () => import('@/views/topics/edit'),
        },
        // ---------------------- Regions ----------------------
        {
          path: '/regions',
          name: 'RegionIndex',
          component: () => import('@/views/regions/index'),
        },
        {
          path: '/regions/new',
          name: 'RegionNew',
          component: () => import('@/views/regions/new'),
        },
        {
          path: '/regions/:id',
          name: 'RegionShow',
          component: () => import('@/views/regions/show'),
        },
        {
          path: '/regions/:id/edit',
          name: 'RegionEdit',
          component: () => import('@/views/regions/edit'),
        },
        // ---------------------- MarketAreas ----------------------
        {
          path: '/market_areas',
          name: 'MarketAreaIndex',
          component: () => import('@/views/market_areas/index'),
        },
        {
          path: '/market_areas/new',
          name: 'MarketAreaNew',
          component: () => import('@/views/market_areas/new'),
        },
        {
          path: '/market_areas/:id',
          name: 'MarketAreaShow',
          component: () => import('@/views/market_areas/show'),
        },
        {
          path: '/market_areas/:id/edit',
          name: 'MarketAreaEdit',
          component: () => import('@/views/market_areas/edit'),
        },
        // ---------------------- Activities ----------------------
        {
          path: '/activities',
          name: 'ActivityIndex',
          component: () => import('@/views/activities/index'),
        },
        {
          path: '/activities/new',
          name: 'ActivityNew',
          component: () => import('@/views/activities/new'),
        },
        {
          path: '/activities/:id',
          name: 'ActivityShow',
          component: () => import('@/views/activities/show'),
        },
        {
          path: '/activities/:id/edit',
          name: 'ActivityEdit',
          component: () => import('@/views/activities/edit'),
        },
        // ---------------------- TimeAmounts ----------------------
        {
          path: '/time_amounts',
          name: 'TimeAmountIndex',
          component: () => import('@/views/time_amounts/index'),
        },
        {
          path: '/time_amounts/new',
          name: 'TimeAmountNew',
          component: () => import('@/views/time_amounts/new'),
        },
        {
          path: '/time_amounts/:id',
          name: 'TimeAmountShow',
          component: () => import('@/views/time_amounts/show'),
        },
        {
          path: '/time_amounts/:id/edit',
          name: 'TimeAmountEdit',
          component: () => import('@/views/time_amounts/edit'),
        },
        // ---------------------- UserRoles ----------------------
        {
          path: '/user_roles',
          name: 'UserRoleIndex',
          component: () => import('@/views/user_roles/index'),
        },
        {
          path: '/user_roles/new',
          name: 'UserRoleNew',
          component: () => import('@/views/user_roles/new'),
          props: true,
        },
        {
          path: '/user_roles/:id',
          name: 'UserRoleShow',
          component: () => import('@/views/user_roles/show'),
        },
        {
          path: '/user_roles/:id/edit',
          name: 'UserRoleEdit',
          component: () => import('@/views/user_roles/edit'),
        },
        // ---------------------- TraineeRoles ----------------------
        {
          path: '/trainee_roles',
          name: 'TraineeRoleIndex',
          component: () => import('@/views/trainee_roles/index'),
        },
        {
          path: '/trainee_roles/new',
          name: 'TraineeRoleNew',
          component: () => import('@/views/trainee_roles/new'),
        },
        {
          path: '/trainee_roles/:id',
          name: 'TraineeRoleShow',
          component: () => import('@/views/trainee_roles/show'),
        },
        {
          path: '/trainee_roles/:id/edit',
          name: 'TraineeRoleEdit',
          component: () => import('@/views/trainee_roles/edit'),
        },
        // ---------------------- RegionUsers ----------------------
        {
          path: '/region_users',
          name: 'RegionUserIndex',
          component: () => import('@/views/region_users/index'),
        },
        {
          path: '/region_users/new',
          name: 'RegionUserNew',
          component: () => import('@/views/region_users/new'),
        },
        {
          path: '/region_users/:id',
          name: 'RegionUserShow',
          component: () => import('@/views/region_users/show'),
        },
        {
          path: '/region_users/:id/edit',
          name: 'RegionUserEdit',
          component: () => import('@/views/region_users/edit'),
        },
        // ---------------------- Phases ----------------------
        {
          path: '/phases',
          name: 'PhaseIndex',
          component: () => import('@/views/phases/index'),
        },
        {
          path: '/phases/new',
          name: 'PhaseNew',
          component: () => import('@/views/phases/new'),
        },
        {
          path: '/phases/:id',
          name: 'PhaseShow',
          component: () => import('@/views/phases/show'),
        },
        {
          path: '/phases/:id/edit',
          name: 'PhaseEdit',
          component: () => import('@/views/phases/edit'),
        },
        // ----------------------- Users ------------------------
        {
          path: '/users',
          name: 'UserIndex',
          component: () => import('@/views/users/index'),
        },
        {
          path: '/users/:id',
          name: 'UserShow',
          component: () => import('@/views/users/show'),
        },
        {
          path: '/users/new',
          name: 'UserNew',
          component: () => import('@/views/users/new'),
        },
        {
          path: '/users/:id/edit',
          name: 'UserEdit',
          component: () => import('@/views/users/edit'),
        },
        {
          path: '/trainees',
          name: 'UserTraineeIndex',
          component: () => import('@/views/users/trainees'),
        },
        {
          path: '/coaches',
          name: 'UserCoachesIndex',
          component: () => import('@/views/users/coaches'),
        },
      ],
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/authentication/Login'),
    },
    {
      path: '/account_recovery',
      name: 'AccountRecovery',
      component: () => import('@/views/authentication/AccountRecovery'),
    },
    {
      path: '/recovery',
      name: 'PasswordReset',
      component: () => import('@/views/authentication/PasswordReset'),
    },
  ],
});

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => AuthService.checkAuth(next, from, to));

export default router;
